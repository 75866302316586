import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import './ApexChart.css';

const API_URL = 'http://localhost:3300/api';

function ApexChart({ seriesData, width, height }) {
  const [dataSeries, setDataSeries] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // When seriesData changes, update dataSeries
    // Ensure that the timestamp values are converted to numbers
    const formattedData = seriesData.map((dataPoint) => ({
      x: Number(dataPoint.x), // Convert to number
      y: dataPoint.y,
    }));
    setDataSeries(formattedData);
  }, [seriesData]);

  useEffect(() => {
    // Whenever dataSeries changes, update chartData
    setChartData([
      {
        series: [
          {
            name: 'Price',
            data: dataSeries, // Use dataSeries here
          },
        ],
        options: {
          chart: {
            id: 'chart4',
            group: 'social',
            type: 'area',
            height: height,
            width: width,
          },
          colors: ['#b1b9c4'],
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            type: 'datetime', // Use datetime type for x-axis
            labels: {
              style: {
                colors: 'black',
              },
            },
            tooltip: {
              enabled: false, // Disable tooltip for x-axis
            },
          },
          yaxis: {
            opposite: true,
            labels: {
              style: {
                colors: 'black',
              },
              formatter: function (val) {
                // Round the value to two decimal places
                const roundedValue = Math.round(val * 100) / 100;
                // Convert the rounded value to a string and remove trailing ".00"
                const formattedValue = roundedValue
                  .toFixed(2)
                  .replace(/\.00$/, '');
                // Use commas for thousands separator
                return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              },
            },
          },
          tooltip: {
            theme: 'dark',
            style: {
              fontSize: '14px',
            },
            x: {
              format: 'HH:mm', // Format tooltip x value as HH:mm
            },
          },
        },
      },
    ]);
  }, [dataSeries]);

  return (
    <div id='wrapper' className='chart-container'>
      {/* Add a CSS class */}
      {chartData.map((chart, index) => (
        <div key={index} className=''>
          <ReactApexChart
            options={chart.options}
            // series={chart.series}
            series={[
              // Create a series with a name and the actual data points
              {
                name: 'Price',
                data: dataSeries,
              },
            ]}
            type={chart.options.chart.type}
            height={chart.options.chart.height}
            width={chart.options.chart.width}
          />
        </div>
      ))}
    </div>
  );
}

export default ApexChart;
